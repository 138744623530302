import React from 'react';
import Content from '../common/containers/Content';
import PrivatePage from '../common/containers/PrivatePage';
import Document from './components/Document';
import Row from '../common/containers/Row';
import Col from '../common/containers/Col';
import { withNamespaces } from 'react-i18next';
import DocumentHistory from './DocumentHistory';

class DocumentHistoryPage extends React.Component {
  constructor(props) {
    super(props);
    this.documentId = Number.parseInt(this.props.match.params.documentId);
  }

  render() {
    const { i18n } = this.props;
    return (
      <PrivatePage title={i18n.t('Histórico de modificações')}>
        <Content>
          <Row>
            <Col md={10} mdOffset={1}>
              <Document documentId={this.documentId} isInTrash={false} />
            </Col>
          </Row>
          <Row>
            <Col md={10} mdOffset={1}>
              <DocumentHistory documentId={this.documentId} />
            </Col>
          </Row>
        </Content>
      </PrivatePage>
    );
  }
}

export default withNamespaces()(DocumentHistoryPage);
