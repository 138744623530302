import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../containers/Modal';

const ICONS_MAP = {
  success: 'mdi-check',
  info: 'mdi-info-outline',
  warning: 'mdi-alert-triangle',
  danger: 'mdi-close-circle-o',
  noicon: '',
};

export default class ModalAlert extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      show,
      title,
      onClose,
      footer,
      isProcessing,
      type,
      children,
      ...rest
    } = this.props;
    return (
      <Modal
        show={show}
        title={title}
        onCancel={onClose}
        footer={footer}
        isProcessing={isProcessing}
        {...rest}
      >
        <div className={ICONS_MAP[type] ? 'text-center' : ''}>
          {ICONS_MAP[type] && (
            <div className={`text-${type}`}>
              <span className={`modal-main-icon mdi ${ICONS_MAP[type]}`} />
            </div>
          )}
          {children}
        </div>
      </Modal>
    );
  }
}

ModalAlert.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(ICONS_MAP)),
  onClose: PropTypes.func,
  footer: PropTypes.element,
  isProcessing: PropTypes.bool,
};

ModalAlert.defaultProps = {
  type: 'success',
  show: false,
  isProcessing: false,
};
