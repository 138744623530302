import React from 'react';
import PropTypes from 'prop-types';
import Ajax from '../common/ajax';
import Config from '../config';
import PaginationWithClick from '../common/components/PaginationWithClick';
import LocaleUtils from '../common/LocaleUtils';
import DocumentHistoryCompare from './DocumentHistoryCompare';
import Row from '../common/containers/Row';
import Col from '../common/containers/Col';
import Panel from '../common/containers/Panel';
import { withNamespaces } from 'react-i18next';
import Waiting from '../common/containers/Waiting';
import DocumentHistoryRecoverButton from './DocumentHistoryRecoverButton';
import Select from '../common/components/form/Select';

class DocumentHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentProcessing: true,
      historyPagination: null,
      historyRows: null,
      historyProcessing: true,
      listType: 'change',
    };
    this.fetchHistory = this.fetchHistory.bind(this);
    this.fetchHistoryByDay = this.fetchHistoryByDay.bind(this);
    this.changeListType = this.changeListType.bind(this);

    this.historyFields = [
      'id',
      'history_id',
      'page_count',
      'history_date',
      'history_user',
      'is_compiled',
    ];
  }

  componentDidMount() {
    this.fetchHistory();
  }

  fetchHistory(
    url = `${Config.apiHost}documents/${this.props.documentId}/history/`
  ) {
    this.setState((state) => ({
      ...state,
      historyProcessing: true,
    }));
    Ajax.get(url, this.historyFields).done((pagination) => {
      this.setState((state) => ({
        ...state,
        historyPagination: pagination,
        historyRows: pagination.results.map((history, index) => {
          if (index < pagination.results.length) {
            return this.mountHistoryRow(history, pagination.results[index + 1]);
          } else {
            return this.mountHistoryRow(history);
          }
        }),
        historyProcessing: false,
      }));
    });
  }

  fetchHistoryByDay(
    url = `${Config.apiHost}documents/${this.props.documentId}/history_by_day/`
  ) {
    this.setState((state) => ({
      ...state,
      historyProcessing: true,
    }));
    Ajax.get(url, this.historyFields).done((results) => {
      this.setState((state) => ({
        ...state,
        historyPagination: null,
        historyRows: results.map((history, index) => {
          if (index < results.length) {
            return this.mountHistoryRow(history, results[index + 1]);
          } else {
            return this.mountHistoryRow(history);
          }
        }),
        historyProcessing: false,
      }));
    });
  }

  changeListType(listType) {
    this.setState((state) => ({ ...state, listType }));

    if (listType === 'change') {
      this.fetchHistory();
    } else {
      this.fetchHistoryByDay();
    }
  }

  mountHistoryRow(history, prev_history) {
    const { i18n, documentId } = this.props;
    return (
      <tr key={history.history_id}>
        <td>{history.history_id}</td>
        <td>{LocaleUtils.fromNow(history.history_date)}</td>
        <td>{history.history_user.email}</td>
        <td>{history.page_count}</td>
        <td>{history.is_compiled ? i18n.t('Sim') : i18n.t('Não')}</td>
        <td>
          {prev_history && (
            <DocumentHistoryCompare
              documentId={documentId}
              prevRecord={prev_history.history_id}
              nextRecord={history.history_id}
            />
          )}
        </td>
        <td>
          <DocumentHistoryRecoverButton
            history={history}
            documentId={documentId}
          />
        </td>
      </tr>
    );
  }

  renderTable() {
    const { i18n, asPanel } = this.props;
    const table = (
      <>
        <Col md={4}>
          <Select
            value={this.state.listType}
            options={[
              { value: 'change', label: 'Listar todas as alterações' },
              { value: 'day', label: 'Listar alterações agrupadas por dia' },
            ]}
            onChange={(e) => this.changeListType(e.target.value)}
          />
        </Col>

        <table className="table table-condensed table-hover">
          <thead>
            <tr>
              <th>Nº</th>
              <th>Data</th>
              <th>Alterador por</th>
              <th>Páginas</th>
              <th>PDF</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>{this.state.historyRows}</tbody>
        </table>
      </>
    );

    if (asPanel) {
      return (
        <Panel
          title={i18n.t('Histórico de modificações')}
          isPanelTable={true}
          isProcessing={this.state.historyProcessing}
        >
          {table}
        </Panel>
      );
    }

    return (
      <Waiting isProcessing={this.state.historyProcessing}>{table}</Waiting>
    );
  }

  render() {
    return (
      <>
        {this.renderTable()}
        {this.state.historyPagination && (
          <Row>
            <Col md={12}>
              <PaginationWithClick
                handleClick={this.fetchHistory}
                pagination={this.state.historyPagination}
              />
            </Col>
          </Row>
        )}
      </>
    );
  }
}

DocumentHistory.propTypes = {
  documentId: PropTypes.number.isRequired,
  asPanel: PropTypes.bool,
};

DocumentHistory.defaultProps = {
  asPanel: true,
};

export default withNamespaces()(DocumentHistory);
