import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import Ajax from '../common/ajax';
import ModalAlert from '../common/components/ModalAlert';
import Col from '../common/containers/Col';
import Modal from '../common/containers/Modal';
import Row from '../common/containers/Row';
import Config from '../config';
import './diff.css';

class DocumentHistoryCompare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      showCompareModal: false,
      processing: false,
      documentBefore: null,
      documentAfter: null,
      errorMessage: null,
    };
    this.onCompareClick = this.onCompareClick.bind(this);
  }

  onCompareClick() {
    this.setState((state) => ({
      ...state,
      errorMessage: null,
      showCompareModal: true,
      data: null,
      processing: true,
    }));

    const url = `${Config.apiHost}documents/${this.props.documentId}/history_compare/?prev_record=${this.props.prevRecord}&next_record=${this.props.nextRecord}`;

    Ajax.get(url)
      .done((result) => {
        this.setState((state) => ({
          ...state,
          processing: false,
          data: result,
        }));
      })
      .fail((jqXHR) => {
        this.setState((state) => ({
          ...state,
          errorMessage: jqXHR.responseJSON.detail,
          showCompareModal: false,
          processing: false,
        }));
      });
  }

  render() {
    const { i18n } = this.props;
    const versions = `${this.props.nextRecord} vs. ${this.props.prevRecord}`;

    return (
      <>
        <a
          role="button"
          className={this.props.className}
          onClick={this.onCompareClick}
        >
          <i className="icon mdi mdi-compare" />{' '}
          {i18n.t('Comparar com anterior')}
        </a>

        <Modal
          title={i18n.t('Comparação de diferenças') + `: ${versions}`}
          show={this.state.showCompareModal}
          onCancel={() =>
            this.setState((s) => ({
              ...s,
              showCompareModal: false,
            }))
          }
          customWidth="900px"
          bodyMaxHeight="calc(80vh)"
          isProcessing={this.state.processing}
        >
          {this.state.data && (
            <>
              <Row>
                <Col md={12}>
                  {i18n.t('Legenda')}:
                  <ins style={{ color: '#9e9e9e' }} className="label">
                    {i18n.t('Inserido')}
                  </ins>{' '}
                  -
                  <del style={{ color: '#9e9e9e' }} className="label">
                    {i18n.t('Removido')}
                  </del>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.data.document_diff,
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </Modal>

        <ModalAlert
          onClose={() => this.setState((s) => ({ ...s, errorMessage: null }))}
          type="danger"
          show={!!this.state.errorMessage}
        >
          <h3>{this.state.errorMessage}</h3>
        </ModalAlert>
      </>
    );
  }
}

DocumentHistoryCompare.propTypes = {
  documentId: PropTypes.number.isRequired,
  prevRecord: PropTypes.number.isRequired,
  nextRecord: PropTypes.number.isRequired,
};

export default withNamespaces()(DocumentHistoryCompare);
