import React from 'react';
import PropTypes from 'prop-types';
import Ajax from '../common/ajax';
import Config from '../config';
import LocaleUtils from '../common/LocaleUtils';
import Button from '../common/components/Button';
import ModalConfirm from '../common/containers/ModalConfirm';
import URLS from '../urls';
import { withNamespaces } from 'react-i18next';
import ModalAlert from '../common/components/ModalAlert';

class DocumentHistoryRecoverButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      showConfirmation: false,
      askConfirmation: false,
      errorMessage: null,
    };
    this.handleRecoverClick = this.handleRecoverClick.bind(this);
    this.handleConfirmRecover = this.handleConfirmRecover.bind(this);
    this.handleCancelRecover = this.handleCancelRecover.bind(this);
  }

  handleRecoverClick() {
    this.setState((state) => ({
      ...state,
      askConfirmation: true,
    }));
  }

  handleConfirmRecover() {
    this.setState((state) => ({
      ...state,
      processing: true,
    }));

    const url = `${Config.apiHost}documents/${this.props.documentId}/history_recover/?history_id=${this.props.history.history_id}`;
    Ajax.get(url)
      .done((response) => {
        this.setState((state) => ({
          ...state,
          processing: false,
          askConfirmation: false,
          showConfirmation: true,
          errorMessage: null,
        }));
        setTimeout(() => {
          window.location = URLS.documentEdit(this.props.documentId);
        }, 2000);
      })
      .fail((jqXHR) => {
        this.setState((state) => ({
          ...state,
          errorMessage: jqXHR.responseJSON.detail,
        }));
      });
  }

  handleCancelRecover() {
    this.setState((state) => ({
      ...state,
      processing: false,
      askConfirmation: false,
      showConfirmation: false,
      errorMessage: null,
    }));
  }

  render() {
    const { i18n, history } = this.props;
    return (
      <>
        <Button
          onClick={this.handleRecoverClick}
          processing={this.state.processing}
        >
          {i18n.t('Recuperar')}
        </Button>

        <ModalConfirm
          show={this.state.askConfirmation}
          type="info"
          onConfirm={this.handleConfirmRecover}
          onCancel={this.handleCancelRecover}
        >
          <h3>
            {i18n.t('Deseja realmente recuperar a versão')}{' '}
            <b>#{history.history_id}</b>, &nbsp;
            {i18n.t('alterada em')}{' '}
            <i>{LocaleUtils.fromNow(history.history_date).toLowerCase()}</i>?
          </h3>
        </ModalConfirm>

        <ModalAlert
          show={this.state.showConfirmation}
          type="success"
          onClose={() => {}}
        >
          <h3>{i18n.t('Versão recuperada com sucesso.')}</h3>
          <h4>{i18n.t('Você será direcionado para a edição do documento.')}</h4>
          <Button processing={true} type="success">
            {i18n.t('Redirecionando')}...
          </Button>
        </ModalAlert>

        <ModalAlert
          show={!!this.state.errorMessage}
          type="danger"
          onClose={this.handleCancelRecover}
        >
          <h3>{this.state.errorMessage}</h3>
        </ModalAlert>
      </>
    );
  }
}

DocumentHistoryRecoverButton.propTypes = {
  history: PropTypes.object.isRequired,
  documentId: PropTypes.number.isRequired,
};

export default withNamespaces()(DocumentHistoryRecoverButton);
